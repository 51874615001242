<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-5">
          <div class="text-left text-white" v-if="$i18n.locale === 'ru'">
            <p>
              <b class="text-left cs-text-color"
              >Продолжительность турнира составляет не более одного дня.</b
              >
            </p>
            <p>
              Турнир состоит из 3 матчей (формат Bo11): 3 матча группового этапа. Каждый матч состоит из 11 геймов. Игра ведется без дополнительного времени. Количество участников турнира – 3 команды по 3 человека в каждой.
            </p>
            <p>
              <b class="text-left cs-text-color"
              >Турнир проводится в один этап:</b
              ><br />
            </p>
            <p>
              Групповой этап. Встречи группового этапа проводятся только в основное время без экстра-таймов. В матче не может быть зафиксирована ничья.
            </p>
            <p>
              Все команды играют в один круг между собой. Победитель выявляется на основании лучших показателей – набранных очков.
            </p>
            <p>
              В ходе проведения группового этапа турнира команды набирают игровые очки, которые по итогам каждой встречи турнира складываются. Итоговое место команды в групповом этапе определяется по сумме набранных очков.
            </p>
            <p>- За победу в гейме команде начисляются 1 очко</p>
            <p>- Проигравшей команде очки не начисляются</p>
            <p>
              Команда, первая набравшая 6 очков в матче, объявляется победителем матча. Победитель матча получает 1 очко в общий зачет, проигравшая команда очков не получает.
            </p>
            <p>
              Занятые командами места в соревновании определяются по турнирной таблице, составленной в порядке убывания очков общего зачета.
            </p>
            <p>
              В случае если одинаковое количество очков было набрано тремя и более командами, победитель определяется согласно правилам, указанным ниже (по ранжированию):
            </p>
            <p>- по разнице выигранных и проигранных геймов во всех матчах;</p>
            <p>- по количеству выигранных геймов во всех матчах;</p>
            <p>- по разнице количества уничтожений и смертей во всех матчах;</p>
            <p>- по количеству уничтоженных во всех матчах;</p>
            <p>- по жребию.</p>
            <p>
              По окончании группового этапа производится подсчет очков, по итогам которого выявляется победитель турнира.
            </p>
          </div>

          <div class="text-left text-white" v-else>
            <p>
              <b class="text-left cs-text-color">The tournament lasts no more than one day.</b>
            </p>
            <p>
              The tournament consists of 3 matches (Bo11 format): 3 group stage matches. Each match consists of 11 games. The game is played without extra time. The number of tournament participants is 3 teams with 3 people in each.
            </p>
            <p>
              <b class="text-left cs-text-color">The tournament is held in one stage:</b><br />
            </p>
            <p>
              Group stage. Group stage matches are held only in regular time without extra times. A draw cannot be recorded in a match.
            </p>
            <p>
              All teams play in one round among themselves. The winner is determined based on the best indicators - points scored.
            </p>
            <p>
              During the group stage of the tournament, teams score game points, which are added up at the end of each tournament match. The final place of the team in the group stage is determined by the sum of points scored.
            </p>
            <p>- For winning a game, the team is awarded 1 point</p>
            <p>- The losing team is not awarded points</p>
            <p>
              The team that first scores 6 points in the match is declared the winner of the match. The winner of the match gets 1 point in the overall standings, the losing team gets no points.
            </p>
            <p>
              The places taken by the teams in the competition are determined by the tournament table, compiled in descending order of the overall standings points.
            </p>
            <p>
              If the same number of points was scored by three or more teams, the winner is determined according to the rules specified below (by ranking):
            </p>
            <p>- by the difference in won and lost games in all matches;</p>
            <p>- by the number of won games in all matches;</p>
            <p>- by the difference in the number of destructions and deaths in all matches;</p>
            <p>- by the number of destructions in all matches;</p>
            <p>- by lot.</p>
            <p>
              At the end of the group stage, the points are counted, according to the results of which the winner of the tournament is determined.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "mir-tankov_rules",
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: this.$t("meta.sports.mir-tankov.rules.title"),
      titleTemplate: "H2H Liga | %s",
      meta: [
        {
          name: "description",
          content: this.$t("meta.sports.mir-tankov.rules.description")
        }
      ]
    };
  }
};
</script>

<style scoped>
.border_title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
